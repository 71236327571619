import { useState, useEffect } from "react";

import './PointImg.css';

import { SvgDelete, SvgImg } from "components/SvgFile";

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

export default function PointImg(props){

    return(
        <div className="show_page_data">
            <div className="list_type_data">
                <div className="type_title">Imagens com pontos definidos</div>
            </div>
            {
                props.data.map((elem, index)=>{
                    return(
                        <div className="list_input_data space_div list_point" key={ index }>
                            <div className="div_img">
                                <div className="">{ elem.img }</div>
                                <div className="">
                                    <img alt="img" src={ "./assets/" + props.folder + "/" + props.code + "/desktop/" + elem.img } className="img_example" />
                                </div>
                            </div>
                            
                            <div className="div_point">
                                <div className="div_input space_div" style={ { width: '100%' } }>
                                    <input type="text" onChange={ (e)=>{ props.HandleData('name_btn', index, e.target.value) } } value={ elem.name_btn } maxLength="16" required />
                                    <span className="name_input">Nome botão</span>
                                </div>
                                {
                                    elem.point.map((elem_1, index_1)=>{
                                        return(
                                            <div className="show_point" key={ index_1 }>
                                                <div className="number_point">
                                                    <div className="">Ponto { index_1 + 1 }</div>
                                                    <div className="" onClick={ ()=>{ props.DeletePoint(elem_1.id, index, index_1, "Ponto " + (index_1 + 1)); } }>
                                                        <SvgDelete className="icons" color="#F00000" />
                                                    </div>
                                                </div>
                                                <div className="list_opt_point">
                                                    <div className="list_input_data">
                                                        <div className="div_input">
                                                            <select onChange={ (e)=>{ props.HandlePoint('type', index, index_1, e.target.value) } } value={ elem_1.type }>
                                                                <option value="">#</option>
                                                                <option value="text">Texto</option>
                                                                <option value="image">Imagem</option>
                                                                <option value="vimeo">Vídeo Vimeo</option>
                                                                <option value="youtube">Vídeo Youtube</option>
                                                            </select>
                                                            <span className="name_input">Tipo</span>
                                                        </div>
                                                        {
                                                            elem_1.type == 'image' ?
                                                            <span className="div_input div_add_img">
                                                                <div className={ elem_1.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                                    <label>
                                                                        { elem_1.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                                        <input type="file" onChange={ (e)=>{ props.HandlePoint('file', index, index_1, e.target.files[0]); } } accept="image/*" />
                                                                    </label>
                                                                </div>
                                                                {
                                                                    elem_1.file !='' ?
                                                                    <>
                                                                        <div className="delete_img_add" onClick={ ()=>{ props.HandlePoint('file', index, index_1, ''); } }>
                                                                            <SvgDelete className="icons" color="#ffffff" />
                                                                        </div>
                                                                        {
                                                                            elem_1.status == true ?
                                                                            <div className="show_img_" onClick={ ()=>{ props.OpenFile('showImg_local', URL.createObjectURL(elem_1.file)) } }>
                                                                                <SvgImg color="#324d6b" className="icons"/>
                                                                            </div> :
                                                                            <div className="show_img_" onClick={ ()=>{ props.OpenFile('showImg', props.folder + "/" + props.code + "/point/" + elem_1.file) } }>
                                                                                <SvgImg color="#324d6b" className="icons"/>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    : null
                                                                }
                                                            </span> : 
                                                            elem_1.type == 'vimeo' || elem_1.type == 'youtube' ? 
                                                            <div className="div_input space_div">
                                                                <input type="text" onChange={ (e)=>{ props.HandlePoint('video', index, index_1, e.target.value) } } value={ elem_1.video } />
                                                                <span className="name_input">Link do vídeo</span>
                                                            </div> : null
                                                        }
                                                    </div>
                                                    
                                                    <div className="list_input_data">
                                                        <div className="div_input space_div">
                                                            <input type="text" onChange={ (e)=>{ props.HandlePoint('title', index, index_1, e.target.value) } } value={ elem_1.title } maxLength="16" required />
                                                            <span className="name_input">Título</span>
                                                        </div>
                                                    </div>
                                                    
                                                    {
                                                        elem_1.type == 'text' ?
                                                        <div className="list_input_data">   
                                                            <div className="div_input space_div">
                                                                <JoditEditor config={ config } value={ elem_1.text ? elem_1.text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => props.HandlePoint('text', index, index_1, newContent) } />
                                                            </div>
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}