import React, { useState, useEffect } from "react";

import './TypeSizeIconMap.css';

import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_SizeIcon } from "services/RegisterData";

import { SvgClose } from "components/SvgFile";

export default function PopUP_SizeIcon(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idIcon, setIdIcon ] = useState(0);
    const [ type, setType ]     = useState(1);

    function SaveData(){
        modalData.setLoading(true);
        Reg_SizeIcon(userId, idIcon, type, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });        
    }

    function CallbackSuccess(){
        ClosePopUp()
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);

        SetModalState('SizeIcon', false);
    }

    function CallbackError(){
        ClosePopUp()
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
        
        SetModalState('SizeIcon', false);
    }

    function ClosePopUp(){
        modalData.setLoading(false);
        SetModalState('SizeIcon', false);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('SizeIcon', setModaldata);
        RegisterModalObserver('SizeIcon', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setIdIcon(modalData.idIcon);
            setType(modalData.type)
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all SizeIcon">
                        <div className="div_data type_div">
                            <div className="title">Tamanho do ícone</div>
                            <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data size_column" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <div className="div_examplo">
                                    <div className="div_img">
                                        <img alt="icon 1" src="./assets/opt1.png" className="icons_img_1" />
                                    </div>
                                    <div className={ type == 1 ? "name_opt name_opt_active" : "name_opt" } onClick={ ()=>{ setType(1) } }>Pequeno</div>
                                </div>
                                <div className="div_examplo">
                                    <div className="div_img">
                                        <img alt="icon 1" src="./assets/opt1.png" className="icons_img_2" />
                                    </div>
                                    <div className={ type == 2 ? "name_opt name_opt_active" : "name_opt" } onClick={ ()=>{ setType(2) } }>Médio</div>
                                </div>
                                <div className="div_examplo">
                                    <div className="div_img">
                                        <img alt="icon 1" src="./assets/opt1.png" className="icons_img_3" />
                                    </div>
                                    <div className={ type == 3 ? "name_opt name_opt_active" : "name_opt" } onClick={ ()=>{ setType(3) } }>Grande</div>
                                </div>
                            </div>
                            <div className="register_data button_profile" onClick={ ()=>{ SaveData() } }>
                                Cadastrar
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
