
import { useState } from "react";

import './Login.css';

import { SvgLogoTipo, SvgSetaRight_opt_2 } from "components/SvgFile";

import { Access } from "services/AccessLogin";

export default function Login(){

    const [ email, setEmail ]       = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function saveData(event){
        event.preventDefault();
        Access('logar', email, password, ()=>{ }, CallbackError);
    }

    function CallbackError(){
        setMsgError('E-mail ou senha incorreta!');
    }

    return(
        <form onSubmit={ saveData } className="Login">
            <div className="div_data_login">
                <div className="show_data_login">                    
                    <div className="div_logotipo">
                        <SvgLogoTipo className="logotipo" />
                    </div>
                    <div className="div_text">Welcome back!</div>
                    <div className="div_input">
                        <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } required placeholder="Type your name" />
                    </div>
                    <div className="div_input">
                        <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } value={ password.replaceAll(' ', '') } required placeholder="Type your password" />
                    </div>
                    <div className="type_save">
                        <label className="div_save">
                            <div className="input_checkbox">
                                <input type="checkbox" className="checkbox" />
                            </div>
                            <div className="text">Remember me</div>
                        </label>
                        <div className="div_recover">Forgot password?</div>
                    </div>
                    <button type="submit" className="div_logar">
                        <div type="submit" className="div_btn">
                            <div className="">Log in</div>
                            <div className="">
                                <SvgSetaRight_opt_2 className="icons" color="#0C0507" />
                            </div>
                        </div>
                    </button>
                </div>
            </div>

            <div className="div_img_bg">
                <img alt="bg" src="./assets/bg_login.png" className="img_bg_login" />
            </div>
        </form>
    )
}
