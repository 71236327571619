import { useState, useEffect } from "react";

import './ScreenInitial.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgVideo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Reg_Project, Reg_ScreenInitial } from "services/RegisterData";

export default function Page_ScreenInitial(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('screen_initial'));

    const [ logo, setLogo ]           = useState(InitialData('logo'));
    const [ status, setStatus ]       = useState(false);
    const [ type, setType ]           = useState(InitialData('type'));
    const [ cover, setCover ]         = useState(InitialData('cover'));
    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ copywrite, setCopywrite ] = useState(InitialData('copywrite'));
    const [ nameBtn, setNameBtn ]     = useState(InitialData('name_btn'));
    const [ text, setText ]           = useState(InitialData('text'));

    function InitialData(type){
        return dataPage[type];
    }

    function AddFile(value){
        if(value){
            setLogo(value);
            setStatus(true);
        }else {
            setLogo('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_ScreenInitial(userId, logo, type, cover, title, copywrite, nameBtn, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('about', setDataPage);

        return ()=>{
            UnRegisterDataPage('about', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('screen_initial'));

        setLogo(InitialData('logo'));
        setStatus(false);
        setType(InitialData('type'));
        setCover(InitialData('cover'));
        setTitle(InitialData('title'));
        setCopywrite(InitialData('copywrite'));
        setNameBtn(InitialData('name_btn'));
        setText(InitialData('text'));
    }, [dataPage]);

    return(
        <div className="Page_ScreenInitial">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Tela inicial
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                            <span className="name_input">Título</span>
                        </div>
                        <div className="div_input">
                            <input type="text" onChange={ (e)=>{ setNameBtn(e.target.value) } } value={ nameBtn } maxLength="40" />
                            <span className="name_input">Nome do botão</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Logo adicionado" : "Adicionar logo" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                logo !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(logo)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', logo) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>

                    <div className="list_input_data">
                        <div className="div_input">
                            <select className="data_select" onChange={ (e)=>{ setType(e.target.value) } } value={ type }>
                                <option value="vimeo">Vimeo</option>
                                <option value="youtube">Youtube</option>
                            </select>
                            <span className="name_input">Tipo de capa</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setCover(e.target.value) } } value={ cover.replaceAll(' ', '') } />
                            <span className="name_input">Link do vídeo</span>
                        </div>
                        {
                            cover == "" ? null :
                            type == 'vimeo' ? 
                            <div className="div_input" onClick={ ()=>{ OpenFile('vimeo', cover) } }>
                                <SvgVideo color="#324d6b" className="icons"/>
                            </div> :
                            <div className="div_input" onClick={ ()=>{ OpenFile('youtube', cover) } }>
                                <SvgYoutube color="#324d6b" className="icons"/>
                            </div>                                        
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setCopywrite(e.target.value) } } value={ copywrite } maxLength="140" />
                            <span className="name_input">Copywrite</span>
                        </div>
                    </div>

                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ text.replaceAll('&#34;', '"') } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}