import { useState, useEffect } from "react";

import './VirtualTour.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgLink, SvgLink_opt2, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Reg_VirtualTour } from "services/RegisterData";

export default function Page_VirtualTour(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ dataPage, setDataPage ] = useState(GetDataPage('virtual_tour'));
    const [ title, setTitle ]       = useState(InitialData('title'));
    const [ text, setText ]         = useState(InitialData('text'));
    const [ listLink, setListLink ] = useState(InitialData('list_link'));

    function InitialData(type){
        return dataPage[type];
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...listLink];
            newData.splice(index, 1);
            setListLink(newData);

        }else {
            SetModalData('Confirmation', { "origin": "virtual_tour", "type": "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function HandleData(type, index, value){
        const newData = [...listLink];
        newData[index][type] = value;
        setListLink(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_VirtualTour(userId, title, text, listLink, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('virtual_tour', setDataPage);

        return ()=>{
            UnRegisterDataPage('virtual_tour', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('virtual_tour'));

        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setListLink(InitialData('list_link'));
    }, [dataPage]);

    return(
        <div className="Page_Project">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Tour Virtual
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                            <span className="name_input">Título</span>
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ text.replaceAll('&#34;', '"') } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Lista de link</div>
                        <div className="new_data_page" onClick={ ()=>{ setListLink([...listLink, { "id": 0, "title": "", "link": "" }]) } }>
                            Adicionar novo link
                        </div>
                    </div>
                    {
                        listLink.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input">
                                        <input type="text" onChange={ (e)=>{ HandleData('title', index, e.target.value); } } value={ elem.title } maxLength="140" />
                                        <span className="name_input">Título</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('link', index, e.target.value); } } value={ elem.link.replaceAll(' ', '') } maxLength="140" />
                                        <span className="name_input">Link do tour virtual</span>
                                    </div>
                                    {
                                        elem.link == "" ? null : 
                                        <div className="div_input">
                                            <a href={ elem.link } target="_blank">
                                                <SvgLink_opt2 className="icons" color="#2D3C53" />
                                            </a>
                                        </div>
                                    }
                                    <div className="div_input" onClick={ ()=>{ DeleteData(elem.id, index, elem.name); } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}