import { useState, useEffect } from "react";

import './Map2.css';

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_Map2 } from "services/RegisterData";

export default function Page_Map2(props){

    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('map_2'));

    const [ listMap, setListMap ] = useState(dataPage);

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function HandleData(type, index, value){
        const newData = [...listMap];
        if(type == 'file'){
            if(value){
                newData[index]['file']   = value;
                newData[index]['status'] = true;
            }else {
                newData[index]['file']   = "";
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setListMap(newData);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...listMap];
            newData.splice(index, 1);
            setListMap(newData);

        }else {
            SetModalData('Confirmation', { "origin": "map_2", "type": "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Map2(userId, listMap, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('map_2', setDataPage);

        return ()=>{
            UnRegisterDataPage('map_2', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('map_2'));
        setListMap(dataPage);
    }, [dataPage]);

    return(
        <div className="Page_Project">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Mapa 2
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Imagens</div>
                        <div className="new_data_page" onClick={ ()=>{ setListMap([...listMap, { "id": 0, "title": "", "file": "", "status": false }]) } }>
                            Adicionar nova imagem
                        </div>
                    </div>
                    {
                        listMap.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" required />
                                        <span className="name_input">Título</span>
                                    </div>
                                    <span className="div_input div_add_img">
                                        <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { elem.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                <input type="file" onChange={ (e)=>{ HandleData('file', index, e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            elem.file !='' ?
                                            <>
                                                <div className="delete_img_add" onClick={ ()=>{ HandleData('file', index, ''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div>
                                                {
                                                    elem.status == true ?
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile("showImg_local", URL.createObjectURL(elem.file)) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div> :
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile("showImg", elem.file) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                            </>
                                            : null
                                        }
                                    </span>
                                    <div className="div_input delete_img" onClick={ ()=>{ DeleteData(elem.id, index, elem.title) } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}