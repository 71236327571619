import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgStar } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Apartments } from "services/RegisterData";

import PointImg from "components/PointImg";

import PopUP_SelectedPointCanvas from "components/PopUp/SelectedPointCanvas";

export default function Page_Apartments_Details(props){

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("apartments"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ status, setStatus ]       = useState(InitialData('status'));
    const [ number, setNumber ]       = useState(InitialData('number'));
    const [ bedrooms, setBedrooms ]   = useState(InitialData('bedrooms'));
    const [ bathrooms, setBathrooms ] = useState(InitialData('bathrooms'));
    const [ flex, setFlex ]           = useState(InitialData('flex'));
    const [ walk, setWalk ]           = useState(InitialData('walk'));
    const [ size, setSize ]           = useState(InitialData('size'));
    const [ cash, setCash ]           = useState(InitialData('cash'));
    const [ guidance, setGuidance ]   = useState(InitialData('guidance'));
    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ text, setText ]           = useState(InitialData('text'));
    const [ position, setPosition ]   = useState(InitialData('position'));
    const [ color, setColor ]         = useState(InitialData('color'));
    const [ listImg, setListImg ]     = useState(InitialData('list_img'));

    const [ file, setFile ]             = useState(InitialData('file'));
    const [ statusFile, setStatusFile ] = useState(false);
    
    const [ mask, setMask ] = useState(ShowMask());
    
    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'flex'){
            return false;
        }
        if(type == 'status'){
            return 'Disponível';
        }
        if(type == "list_img"){
            return [];
        }
        return '';
    }

    function ShowMask(){
        const newData = GetDataPage('apartments_file').filter(item => item.imgFixed == true);
        return newData;
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStatusFile(true);
        }else {
            setFile('');
            setStatusFile(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "only", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function OpenGallery(type, index, file){
        SetModalData('ShowFile', { "type": type, "listFile": listImg, "index": index, "file": file, "folder": "" });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...listImg];
            newData.splice(index, 1);
            setListImg(newData);

        }else {
            SetModalData('Confirmation', { "origin": "apartments", "type" : "delete_gallery", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function HandleData(type, index, value){
        const newData = [...listImg];
        if(type == 'img'){
            if(value){
                newData[index][type]     = value;
                newData[index]['status'] = true;
            }else {
                newData[index][type]     = '';
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setListImg(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Apartments(userId, idPage, status, number, bedrooms, bathrooms, flex, walk, size, cash, guidance, title, text, file, listImg, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'apartments');
        SetListPag('currentPageId', 'remuve');
    }

    function SelectPoint(position, mask, file){
        SetModalData('SelectedPointCanvas', { idPage, position, mask, file });
        SetModalState('SelectedPointCanvas', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterUserData('access', setUserAccess);

        RegisterDataPage('apartments', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterUserPage('access', setUserAccess);

            UnRegisterDataPage('apartments', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("apartments"));
        setIdPage(GetListPag('currentPageId'));

        setStatus(InitialData('status'));
        setNumber(InitialData('number'));
        setBedrooms(InitialData('bedrooms'));
        setBathrooms(InitialData('bathrooms'));
        setFlex(InitialData('flex'));
        setWalk(InitialData('walk'));
        setSize(InitialData('size'));
        setCash(InitialData('cash'));
        setGuidance(InitialData('guidance'));
        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setPosition(InitialData('position'));
        setColor(InitialData('color'));

        setListImg(InitialData('list_img'))

        setFile(InitialData('file'));
        setStatusFile(false);
        
        setMask(ShowMask());
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Apartments_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Apartamentos
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Dados do apartamento</div>
                        {
                            color == '' ? null :
                            <div className="div_data_apart">
                                Posição { position }
                                <div className="color_apartments" style={ { background: 'rgb(' + color + ')' } } />
                            </div>
                        }
                    </div>

                    <div className="list_input_data">
                        <div className="div_input">
                            <select onChange={ (e)=>{ setStatus(e.target.value) } } value={ status }>
                                <option value="Disponível">Disponível</option>
                                <option value="Vendido">Vendido</option>
                                <option value="Reservado">Reservado</option>
                            </select>
                            <span className="name_input">Status</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="number" onChange={ (e)=>{ setNumber(e.target.value) } } value={ number } maxLength="3" required />
                            <span className="name_input">Número</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="number" onChange={ (e)=>{ setBedrooms(e.target.value) } } value={ bedrooms } maxLength="3" required />
                            <span className="name_input">Quartos</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="number" onChange={ (e)=>{ setBathrooms(e.target.value) } } value={ bathrooms } maxLength="3" required />
                            <span className="name_input">Banheiro</span>
                        </div>
                        <div className="div_input">
                            <select onChange={ (e)=>{ setFlex(e.target.value) } } value={ flex }>
                                <option value="true">Sim</option>
                                <option value="false">Não</option>
                            </select>
                            <span className="name_input">Flex</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="number" onChange={ (e)=>{ setWalk(e.target.value) } } value={ walk } maxLength="3" required />
                            <span className="name_input">Andar</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="size" onChange={ (e)=>{ setSize(e.target.value) } } value={ size } maxLength="10" required />
                            <span className="name_input">Tamanho</span>
                        </div>
                        <div className="div_input">
                            <input type="text" className="cash" onChange={ (e)=>{ setCash(e.target.value) } } value={ cash } maxLength="20" required />
                            <span className="name_input">Valor</span>
                        </div>
                        <div className="div_input">
                            <select className="guidance" onChange={ (e)=>{ setGuidance(e.target.value) } } value={ guidance } required>
                                <option value="">#</option>
                                <option value="Norte">Norte</option>
                                <option value="Sul">Sul</option>
                                <option value="Leste">Leste</option>
                                <option value="Oeste">Oeste</option>
                            </select>
                            <span className="name_input">Orientação</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="16" required />
                            <span className="name_input">Título</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ statusFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusFile == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        statusFile == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <div className="show_title">Observação</div>
                            <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Lista das imagens</div>
                        <div className="new_data_page" onClick={ ()=>{ setListImg([...listImg, { "id": 0, "title": "", "img": "", "status": false }]) } }>
                            Adicionar nova imagem
                        </div>
                    </div>                    
                    {
                        listImg.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                        <span className="name_input">Título</span>
                                    </div>
                                    <span className="div_input div_add_img">
                                        <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { elem.status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                <input type="file" onChange={ (e)=>{ HandleData('img', index, e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            elem.img !='' ?
                                            <>
                                                <div className="delete_img_add" onClick={ ()=>{ HandleData('img', index, ''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div>
                                                {
                                                    elem.status == true ?
                                                    <div className="show_img_" onClick={ ()=>{ OpenGallery("showImg_local", 0, URL.createObjectURL(elem.img)) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div> :
                                                    <div className="show_img_" onClick={ ()=>{ OpenGallery("list", index, '') } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                            </>
                                            : null
                                        }
                                    </span>
                                    <div className="div_input delete_img" onClick={ ()=>{ DeleteData(elem.id, index, elem.title) } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                
                {
                    idPage == 0 ? null :
                    <div className="list_mask">
                        {
                            mask.map((elem, index)=>{
                                let showMask      = elem.img.split('.');
                                let classColor    = '';
                                let positionIndex = ''; 

                                if(index <= 1){
                                    positionIndex = 'A';
                                    if(position == 'B'){
                                        classColor = 'bg_img_selected';   
                                    }

                                }else if(index > 1){
                                    positionIndex = 'B';
                                    if(position == 'A'){
                                        classColor = 'bg_img_selected';   
                                    }
                                }                   

                                return(
                                    <div className="show_page_data" key={ index }>
                                        <div className="list_type_data">
                                            <div className="type_title">Posição { positionIndex }</div>
                                            <div className="new_block_text" onClick={ ()=>{ SelectPoint(positionIndex, elem.mask, elem.img) } }>
                                                Selecionar apartamento
                                            </div>
                                        </div>

                                        <div className="div_mask">
                                            <div className={ classColor } />
                                            <img alt={ "img mask_" + index } src={ elem.mask } className="img_selected effect" />
                                        </div>
                                        
                                        <div className="div_img_selected">
                                            <img alt={ "img img_" + index } src={ elem.img } className="img_selected" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div> 
                }               
            </form>

            <PopUP_SelectedPointCanvas />
        </div>
    )
}