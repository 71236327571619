import React, { useState, useEffect } from "react";

import './AddPointMap.css';

import { SvgClose, SvgDelete } from "components/SvgFile";

import { GetUserData } from "interface/Users";

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_PointMap } from "services/RegisterData";
import { GetDataPage } from "interface/Data";

export default function PopUP_AddPointMap(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ lat, setlat ] = useState('');
    const [ lng, setLng ] = useState('');

    const [ type, setType ]     = useState(1);
    const [ title, setTitle ]   = useState('');
    const [ file, setFile ]     = useState('');
    const [ status, setStatus ] = useState(false);

    const [ listOpt, setListOpt ]         = useState([]);
    const [ category, setCategory ]       = useState('');
    const [ newCategory, setNewCategory ] = useState('');

    const [ msgErro, setMsgErro ] = useState('');

    function AddFile(value){
        if(value){
            setStatus(true);
            setFile(value);
        }else {
            setStatus(false);
            setFile('');
        }
    }

    function SaveData(event){
        event.preventDefault();
        Reg_PointMap(lat, lng, type, title, category, newCategory, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp('save');
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        ClosePopUp('cancel');
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(type){
        setlat('');
        setLng('');

        setType(1);
        setTitle('');
        setFile('');
        setStatus(false);

        setListOpt([]);
        setCategory('');
        setNewCategory('');

        if(type == 'cancel'){
            modalData.CancelData(modalData.data.lat, modalData.data.lng);
        }
        SetModalState('AddPointMap', false);
    }

    useEffect(()=>{
        RegisterModalData('AddPointMap', setModaldata);
        RegisterModalObserver('AddPointMap', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setlat(modalData.data.lat);
            setLng(modalData.data.lng);
            setListOpt(GetDataPage('map_list_type'));
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form onSubmit={ SaveData } className="PopUp">
                <div className="all add_point_map">
                    <div className="div_data type_div">
                        <div className="title">
                            Pontos do mapa
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="" onChange={ (e)=>{ setlat(e.target.value) } } value={ lat } style={ { textAlign: 'center' } } />
                                    <span className="name_input">Latitude</span>
                                </div>
                                <div className="div_input space_div">
                                    <input type="text" className="" onChange={ (e)=>{ setLng(e.target.value) } } value={ lng } style={ { textAlign: 'center' } } />
                                    <span className="name_input">Longitude</span>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <select onChange={ (e)=>{ setCategory(e.target.value) } } value={ category } className="" required>
                                        <option value="">Selecione uma categoria</option>
                                        <option value="newCategory">Nova categoria</option>
                                        {
                                            listOpt.map((elem, index)=>{
                                                return(
                                                    <option value={ elem.id } key={ index }>{ elem.name }</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="name_input">Categoria</span>
                                </div>
                                {
                                    category == "newCategory" ?                                         
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ setNewCategory(e.target.value) } } value={ newCategory } required />
                                        <span className="name_input">Nome da nova categoria</span>
                                    </div> : null
                                }
                            </div>
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                                    <span className="name_input">Título</span>
                                </div>
                                <span className="div_input div_add_img">
                                    <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                        <label>
                                            { status == true ? "Svg adicionado" : "Adicionar svg" }
                                            <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" required />
                                        </label>
                                    </div>
                                    {
                                        file == '' ? null :
                                        <>
                                            <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                                <SvgDelete className="icons" color="#ffffff" />
                                            </div>
                                            <img alt="icon svg" src={ URL.createObjectURL(file) } className="show_img_" />
                                        </>
                                    }
                                </span>
                            </div>
                            <div className="list_input_data">
                                <div className="div_examplo">
                                    <div className="" style={ { flexGrow: 1 } }>Tamanho do ícone</div>
                                    <div className="div_img">
                                        <img alt="icon 1" src="./assets/opt1.png" className="icons_img_1" />
                                    </div>
                                    <div className={ type == 1 ? "name_opt name_opt_active" : "name_opt" } onClick={ ()=>{ setType(1) } }>Pequeno</div>
                                </div>
                                <div className="div_examplo">
                                    <div className="" style={ { flexGrow: 1 } }>Tamanho do ícone</div>
                                    <div className="div_img">
                                        <img alt="icon 1" src="./assets/opt1.png" className="icons_img_2" />
                                    </div>
                                    <div className={ type == 2 ? "name_opt name_opt_active" : "name_opt" } onClick={ ()=>{ setType(2) } }>Médio</div>
                                </div>
                                <div className="div_examplo">
                                    <div className="" style={ { flexGrow: 1 } }>Tamanho do ícone</div>
                                    <div className="div_img">
                                        <img alt="icon 1" src="./assets/opt1.png" className="icons_img_3" />
                                    </div>
                                    <div className={ type == 3 ? "name_opt name_opt_active" : "name_opt" } onClick={ ()=>{ setType(3) } }>Grande</div>
                                </div>
                            </div>
                            <div className="list_input_data">
                                <div className="opt_select not_update" onClick={ ()=>{ ClosePopUp('cancel') } }>
                                    Cancelar
                                </div>
                                
                                <button type="submit" className="btn_save opt_select yes_update">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
