let DataPage = {
    "data_general_tour" : [],
    "data_solar"        : [],
    "data_disassemble"  : []
};

let NotifyDataPage = {
    "data_general_tour" : [],
    "data_solar"        : [],
    "data_disassemble"  : []
}

export function CleanDataPoint(key) {
    DataPage[key] = [];
    NotifyDataPage[key].forEach(element => {
        element(DataPage[key]);
    });
}

export function AddListDataSinglePoint(key, value) {
    DataPage[key].push(value);
    NotifyDataPage[key].forEach(element => {
        element(DataPage[key]);
    });
}

export function GetDataPagePoint(key){
    return DataPage[key];
}

export function RegisterDataPagePoint(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPagePoint(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}