import { useState, useEffect } from "react";

import './Project.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";

import { Reg_Project } from "services/RegisterData";

export default function Page_Project(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ dataPage, setDataPage ] = useState(GetDataPage('project'));

    const [ aboutTitle, setAboutTitle ]   = useState(InitialData('about_title'));
    const [ aboutText, setAboutText ]     = useState(InitialData('about_text'));
    const [ aboutFile, setAboutFile ]     = useState(InitialData('about_file'));
    const [ aboutStatus, setAboutStatus ] = useState(false);

    const [ datasheetTitle, setDatasheetTitle ]   = useState(InitialData('datasheet_title'));
    const [ datasheetText, setDatasheetText ]     = useState(InitialData('datasheet_text'));
    const [ datasheetFile, setDatasheetFile ]     = useState(InitialData('datasheet_file'));
    const [ datasheetStatus, setDatasheetStatus ] = useState(false);

    const [ listDevelopers, setListDevelopers ] = useState(InitialData('list_developers'));
    const [ listArchitects, setListArchitects ] = useState(InitialData('list_architects'));

    function InitialData(type){
        return dataPage[type];
    }

    function AddFile(type, value){
        // about
        if(type == 'about'){
            if(value){
                setAboutFile(value);
                setAboutStatus(true);
            }else {
                setAboutFile('');
                setAboutStatus(false);
            }
        }
        // datashet        
        if(type == 'datasheet'){
            if(value){
                setDatasheetFile(value);
                setDatasheetStatus(true);
            }else {
                setDatasheetFile('');
                setDatasheetStatus(false);
            }
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(type, id, index, value){
        if(id == 0){
            if(type == 'architects'){
                const newData = [...listArchitects];
                newData.splice(index, 1);
                setListArchitects(newData);

            }else if(type == 'developers'){

                const newData = [...listDevelopers];
                newData.splice(index, 1);
                setListDevelopers(newData);
            }

        }else {
            SetModalData('Confirmation', { "origin": "project", "type": "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function HandleData(type, input, index, value){
        if(type == 'architects'){
            const newData = [...listArchitects];
            if(input == 'file'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else {
                newData[index][input] = value;
            }
            setListArchitects(newData);

        }else if(type == 'developers'){
            const newData = [...listDevelopers];
            if(input == 'file'){
                if(value){
                    newData[index]['file']   = value;
                    newData[index]['status'] = true;
                }else {
                    newData[index]['file']   = "";
                    newData[index]['status'] = false;
                }
            }else {
                newData[index][input] = value;
            }
            setListDevelopers(newData);
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Project(userId, aboutTitle, aboutText, aboutFile, datasheetTitle, datasheetText, datasheetFile, listDevelopers, listArchitects, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('project', setDataPage);

        return ()=>{
            UnRegisterDataPage('project', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('project'));

        setAboutTitle(InitialData('about_title'));
        setAboutText(InitialData('about_text'));
        setAboutFile(InitialData('about_file'));
        setAboutStatus(false);

        setDatasheetTitle(InitialData('datasheet_title'));
        setDatasheetText(InitialData('datasheet_text'));
        setDatasheetFile(InitialData('datasheet_file'));
        setDatasheetStatus(false);

        setListDevelopers(InitialData('list_developers'));
        setListArchitects(InitialData('list_architects'));
    }, [dataPage]);

    return(
        <div className="Page_Project">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Dados do projeto
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Sobre o projeto</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setAboutTitle(e.target.value) } } value={ aboutTitle } maxLength="140" />
                            <span className="name_input">Título</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ aboutStatus == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { aboutStatus == true ? "Capa adicionada" : "Adicionar capa" }
                                    <input type="file" onChange={ (e)=>{ AddFile('about', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                aboutFile !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('about', ''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        aboutStatus == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(aboutFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', aboutFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ aboutText.replaceAll('&#34;', '"') } onBlur={ newContent => setAboutText(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Desenvolvedores</div>
                        <div className="new_data_page" onClick={ ()=>{ setListDevelopers([...listDevelopers, { "id": 0, "name": "", "office": "", "email": "", "file": "", "status": false }]) } }>
                            Adicionar novo cadastro
                        </div>
                    </div>
                    {
                        listDevelopers.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('developers', 'name', index, e.target.value); } } value={ elem.name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('developers', 'office', index, e.target.value); } } value={ elem.office } maxLength="140" />
                                        <span className="name_input">Cargo</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('developers', 'email', index, e.target.value); } } value={ elem.email } maxLength="140" />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <span className="div_input div_add_img">
                                        <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { elem.status == true ? "Foto adicionada" : "Adicionar foto" }
                                                <input type="file" onChange={ (e)=>{ HandleData('developers', 'file', index, e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            elem.file !='' ?
                                            <>
                                                <div className="delete_img_add" onClick={ ()=>{ HandleData('developers', 'file', index, ''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div>
                                                {
                                                    elem.status == true ?
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.file)) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div> :
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', elem.file) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                            </>
                                            : null
                                        }
                                    </span>
                                    <div className="div_input" onClick={ ()=>{ DeleteData('developers', elem.id, index, elem.name); } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Arquitetos(as)</div>
                        <div className="new_data_page" onClick={ ()=>{ setListArchitects([...listArchitects, { "id": 0, "name": "", "office": "", "email": "", "file": "", "status": false }]) } }>
                            Adicionar novo cadastro
                        </div>
                    </div>
                    {
                        listArchitects.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('architects', 'name', index, e.target.value); } } value={ elem.name } maxLength="140" />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('architects', 'office', index, e.target.value); } } value={ elem.office } maxLength="140" />
                                        <span className="name_input">Cargo</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('architects', 'email', index, e.target.value); } } value={ elem.email } maxLength="140" />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <span className="div_input div_add_img">
                                        <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                            <label>
                                                { elem.status == true ? "Foto adicionada" : "Adicionar foto" }
                                                <input type="file" onChange={ (e)=>{ HandleData('architects', 'file', index, e.target.files[0]); } } accept="image/*" />
                                            </label>
                                        </div>
                                        {
                                            elem.file !='' ?
                                            <>
                                                <div className="delete_img_add" onClick={ ()=>{ HandleData('architects', 'file', index, ''); } }>
                                                    <SvgDelete className="icons" color="#ffffff" />
                                                </div>
                                                {
                                                    elem.status == true ?
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(elem.file)) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div> :
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', elem.file) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                            </>
                                            : null
                                        }
                                    </span>
                                    <div className="div_input" onClick={ ()=>{ DeleteData('architects', elem.id, index, elem.name); } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Ficha de dados</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setDatasheetTitle(e.target.value) } } value={ datasheetTitle } maxLength="140" />
                            <span className="name_input">Título</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ datasheetStatus == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { datasheetStatus == true ? "Capa adicionada" : "Adicionar capa" }
                                    <input type="file" onChange={ (e)=>{ AddFile('datasheet', e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                datasheetFile !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddFile('datasheet', ''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        datasheetStatus == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(datasheetFile)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', datasheetFile) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ datasheetText.replaceAll('&#34;', '"') } onBlur={ newContent => setDatasheetText(newContent) } />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}