import { useState, useEffect } from "react";

import './UsersSite.css';

import { SvgCancel, SvgDelete, SvgEdit, SvgSearch, SvgSetaRight, SvgUser, SvgUserDisabled } from "components/SvgFile";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { SetListPag } from "interface/Page";

export default function Page_UsersSite(props){

    const [ search, setSearch ]     = useState('');
    const [ showData, setShowData ] = useState(GetDataPage('access_dash_site'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('access_dash_site').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(GetDataPage('access_dash_site'));
        }
        setSearch(value);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AltAccess(type, id, value){
        SetModalData('Confirmation', { "origin": "access_dash_site", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "access_dash_site", "type" : "delete_user", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'access_dash_site_details')
        SetListPag('currentPageId', idPage);
    }

    useEffect(()=>{
        RegisterDataPage('access_dash_site', setShowData);

        return ()=>{
            UnRegisterDataPage('access_dash_site', setShowData);
        };
    }, []);

    return(
        <div className="Page_UsersSite">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#6a6a6a" className="icons" />
                    </div>
                    <div className="name_page">
                        Usuários do site
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                    </div>
                    <div className="">
                        Usuários
                    </div>
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="200">E-mail</th>
                            <th width="110">Acesso</th>
                            <th width="40">Foto</th>
                            <th width="90" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                let nivel = '';
                                switch (key.nivel) {
                                    case 0: nivel = "Usuário"; break;
                                    case 1: nivel = "Administrador"; break;
                                    case 2: nivel = "Corretor de imóveis"; break;
                                }

                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.name }</td>
                                        <td>{ key.email }</td>
                                        <td>{ nivel }</td>
                                        <td align="center">
                                            <div>
                                                {
                                                    key.file !='' ?
                                                    <img alt="profile user" src={ key.file } className="icon_user" onClick={ ()=>{ OpenFile('showImg', key.file) } } /> :
                                                    <SvgUser color="#324d6b" className="icon_user" />
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                {
                                                    key.status == 0 ?
                                                    <div onClick={ ()=>{ AltAccess('reactivate_access_dash', key.id, key.name) } } title="Liberar acesso">
                                                        <SvgUserDisabled color="#F00000" className="icons"/>
                                                    </div> :
                                                    <div onClick={ ()=>{ AltAccess('disabled_access_dash', key.id, key.name) } } title="Remover acesso">
                                                        <SvgCancel color="#F00000" className="icons"/>
                                                    </div>
                                                }
                                                <div onClick={ ()=>{ DeleteData(key.id, key.name) } } title="Deletar usuário">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ PageClick(key.id) } } title="Editar usuário">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}