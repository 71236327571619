import { useState, useEffect } from "react";

import './Details.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgStar } from "components/SvgFile";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { Reg_Building } from "services/RegisterData";

export default function Page_Building(props){

    const [ typeData, setTypeData ] = useState('desktop');
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage("apartments_file"));

    const [ imgDesktop, setImgDesktop ]                 = useState(InitialData('desktop'));
    const [ comprDesktop, setComprDesktop ]             = useState([]);
    const [ statusComprDesktop, setStatusComprDesktop ] = useState(false);
    
    const [ imgMobile, setImgMobile ]                 = useState(InitialData('mobile'));
    const [ comprMobile, setComprMobile ]             = useState([]);
    const [ statusComprMobile, setStatusComprMobile ] = useState(false);

    function InitialData(type){
        const newData = dataPage.filter(item => item.type == type);
        return newData;
    }

    function AddComprDesktop(value){
        if(value){
            setComprDesktop(value);
            setStatusComprDesktop(true);
        }else {
            setComprDesktop('');
            setStatusComprDesktop(false);
        }
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "apartments_file", "type" : type, "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    function OpenFile(type, index, mask, imgFixed){
        if(type == "desktop"){
            SetModalData('ShowFile', { "type": "list_select", "mask": mask, "listFile": imgDesktop, "index": index, "folder": type, "imgFixed": imgFixed });
        }else {
            SetModalData('ShowFile', { "type": "list_select", "mask": mask, "listFile": imgMobile, "index": index, "folder": type, "imgFixed": imgFixed });
        }
        
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Building(userId, comprDesktop, comprMobile, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'apartments');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('apartments_file', setDataPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('apartments_file', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("apartments_file"));

        setImgDesktop(InitialData('desktop'));
        setComprDesktop([]);
        setStatusComprDesktop(false);

        setImgMobile(InitialData('mobile'));
        setComprMobile([]);
        setStatusComprMobile(false);
    }, [dataPage]);
    
    return(
        <div className="Page_Building">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Fotos do prédio
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>
                
                <div className="show_page_data">
                    <div className="list_input_data show_list_file">
                        <div className="space_div div_add_img">
                            {
                                imgDesktop.length == 0 ? null :
                                <div className="delete_all" onClick={ ()=>{ DeleteData('delete_all_desktop', "Todas as imagens") } }>
                                    Deletar todas as imagens
                                </div>
                            }

                            <div className={ statusComprDesktop == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { statusComprDesktop == true ? "Imagens adicionadas" : "Adicionar imagem" }
                                    <input type="file" onChange={ (e)=>{ AddComprDesktop(e.target.files); } } accept="image/*" multiple />
                                </label>
                            </div>
                            {
                                comprDesktop == '' ? null :
                                <div className="delete_img_add" onClick={ ()=>{ AddComprDesktop(''); } }>
                                    <SvgDelete className="icons" color="#ffffff" />
                                </div>
                            }
                        </div>
                    </div>

                    <div className="list_input_data">
                        {
                            imgDesktop.map((elem, index)=>{
                                return(
                                    <div className="div_list_file" key={ index }>
                                        <div className="numb_img">{ index + 1 }</div>
                                        <div className="name_img">
                                            { elem.img }
                                            {
                                                elem.imgFixed ? 
                                                <div data-tooltip-id={ "type_data_" + elem.id } data-tooltip-content="Imagem fixada" data-tooltip-place="top">
                                                    <SvgStar className="icons" color="#0b711c" />
                                                </div> : null 
                                            }
                                            <Tooltip id={ "type_data_" + elem.id } />
                                        </div>
                                        <div className="delete_img">
                                            <div className="show_img_" onClick={ ()=>{ OpenFile("desktop", index, elem.mask, elem.imgFixed) } }>
                                                <SvgImg color="#324d6b" className="icons"/>
                                            </div>
                                        </div>
                                        <div className="delete_img" onClick={ ()=>{ DeleteData('delete_building', elem.id, elem.img) } }>
                                            <SvgDelete color="#f00000" className="icons" />
                                        </div>
                                    </div>
                                )
                            })
                        }                             
                    </div>
                </div>
            </form>
        </div>
    )
}