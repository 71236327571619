import { useState, useEffect } from "react";

import './Details.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { SvgDelete, SvgImg, SvgSave, SvgSetaRight, SvgStar, SvgVideo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag, UnRegisterListPag } from "interface/Page";

import { Reg_Video } from "services/RegisterData";

import PointImg from "components/PointImg";

export default function Page_Video_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ dataPage, setDataPage ] = useState(GetDataPage("video"));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ text, setText ]           = useState(InitialData('text'));
    const [ file, setFile ]           = useState(InitialData('file'));
    const [ status, setStatus ]       = useState(false);
    const [ listVideo, setListVideo ] = useState(InitialData('video_list'));

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'video_list'){
            return [];
        }
        return '';
    }

    function AddCover(value){
        if(value){
            setFile(value);
            setStatus(true);
        }else {
            setFile('');
            setStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, index, value){
        if(id == 0){
            const newData = [...listVideo];
            newData.splice(index, 1);
            setListVideo(newData);

        }else {
            SetModalData('Confirmation', { "origin": "video", "type" : "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
            SetModalState('Confirmation', true);
        }
    }

    function HandleData(type, index, value){
        const newData = [...listVideo];
        if(type == 'img'){
            if(value){
                newData[index][type]     = value;
                newData[index]['status'] = true;
            }else {
                newData[index][type]     = '';
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setListVideo(newData);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Video(userId, idPage, title, text, file, listVideo, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'video');
        SetListPag('currentPageId', 'remuve');
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterDataPage('video', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);

            UnRegisterDataPage('video', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage("video"));
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setFile(InitialData('file'));
        setStatus(false);
        setListVideo(InitialData('video_list'));
    }, [dataPage, idPage]);
    
    return(
        <div className="Page_Video_Details">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_fixed page_return" onClick={ ()=>{ ReturnPage() } }>
                            Vídeo
                        </div>
                        <div className="show_icon" onClick={ ()=>{ ReturnPage() } }>
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Detalhes
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="16" required />
                            <span className="name_input">Título</span>
                        </div>
                        <span className="div_input div_add_img">
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Capa adicionada" : "Adicionar capa" }
                                    <input type="file" onChange={ (e)=>{ AddCover(e.target.files[0]); } } accept="image/*" />
                                </label>
                            </div>
                            {
                                file !='' ?
                                <>
                                    <div className="delete_img_add" onClick={ ()=>{ AddCover(''); } }>
                                        <SvgDelete className="icons" color="#ffffff" />
                                    </div>
                                    {
                                        status == true ?
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', file) } }>
                                            <SvgImg color="#324d6b" className="icons"/>
                                        </div>
                                    }
                                </>
                                : null
                            }
                        </span>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div show_data_text">
                            <JoditEditor config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>
                
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Lista de vídeos</div>
                        <div className="new_data_page" onClick={ ()=>{ setListVideo([...listVideo, { "id": 0, "title": "", "type": "youtube", "file": "" }]) } }>
                            Adicionar novo cadastro
                        </div>
                    </div>
                    
                    {
                        listVideo.map((elem, index)=>{
                            return(
                                <div className="list_input_data border_top" key={ index }>
                                    <div className="div_input">
                                        <input type="text" className="title_video" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" required />
                                        <span className="name_input">Título</span>
                                    </div>                                    
                                    <div className="div_input">
                                        <select className="data_select" onChange={ (e)=>{ HandleData('type', index, e.target.value) } } value={ elem.type }>
                                            <option value="vimeo">Vimeo</option>
                                            <option value="youtube">Youtube</option>
                                        </select>
                                        <span className="name_input">Tipo de vídeo</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="text" onChange={ (e)=>{ HandleData('file', index, e.target.value) } } value={ elem.file.replaceAll(' ', '') } />
                                        <span className="name_input">Link do vídeo</span>
                                    </div>
                                    {
                                        elem.file == "" ? null :
                                        elem.type == 'vimeo' ? 
                                        <div className="div_input" onClick={ ()=>{ OpenFile('vimeo', elem.file) } }>
                                            <SvgVideo color="#324d6b" className="icons"/>
                                        </div> :
                                        <div className="div_input" onClick={ ()=>{ OpenFile('youtube', elem.file) } }>
                                            <SvgYoutube color="#324d6b" className="icons"/>
                                        </div>                                        
                                    }
                                    <div className="div_input delete_img" onClick={ ()=>{ DeleteData(elem.id, index, elem.title) } }>
                                        <SvgDelete color="#f00000" className="icons" />
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
        </div>
    )
}