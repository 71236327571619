import { useState, useEffect } from "react";

import './Gallery.css';

import { SvgDelete, SvgEdit, SvgSearch, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { SetListPag } from "interface/Page";

export default function Page_Gallery(props){

    const [ search, setSearch ]     = useState('');
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ showData, setShowData ] = useState(GetDataPage('gallery'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);
    
    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('gallery').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(GetDataPage('gallery'));
        }
        setSearch(value);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'gallery_details')
        SetListPag('currentPageId', idPage);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "gallery", "type" : "delete_data_all", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('gallery', setShowData);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('gallery', setShowData);
        };
    }, []);

    return(
        <div className="Page_Gallery">
            <div className="new_block">
                <div className="div_name_page">
                    <div className="name_fixed">
                        Dashboard
                    </div>
                    <div className="show_icon">
                        <SvgSetaRight color="#6a6a6a" className="icons" />
                    </div>
                    <div className="name_page">
                        Galeria
                    </div>
                </div>
                <div className="input_search">
                    <SvgSearch color="#606875" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required placeholder="buscar" />
                </div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <div className="">
                        <span className="icons_menu">+</span>
                    </div>
                    <div className="">
                        dados
                    </div>
                </div>
            </div>
            <div className="">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th width="40">Capa</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.title }</td>
                                        <td align="center">
                                            <div className="div_icon_cover">
                                                {
                                                    key.file !='' ?
                                                    <img alt="profile user" src={ key.file } className="icon_cover" onClick={ ()=>{ OpenFile('showImg', key.cover) } } /> :
                                                    <img alt="profile user" src="./assets/not_file.jpg" className="icon_cover" />
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ DeleteData(key.id, key.title) } } title="Deletar dado">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ PageClick(key.id) } } title="Editar dado">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}