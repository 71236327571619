import { useState, useEffect } from "react";

import './MapPoi.css';

import JoditEditor from 'jodit-react';
import { config } from "fixedData";

import { SvgDelete, SvgSave, SvgSetaRight } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_MapPoi } from "services/RegisterData";

import GoogleMapReact from 'google-map-react';

import PopUP_AddPointMap from "components/PopUp/AddPointMap";
import PopUP_SizeIcon from "components/PopUp/TypeSizeIconMap";

export default function Page_MapPoi(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ dataPage, setDataPage ]       = useState(GetDataPage('map_poi'));
    const [ category, setCategory ]       = useState(GetDataPage('map_list_type'));
    const [ title, setTitle ]             = useState(InitialData('title'));
    const [ text, setText ]               = useState(InitialData('text'));
    const [ latitude, setLatitude ]       = useState(InitialData('latitude'));
    const [ longitude, setLongitude ]     = useState(InitialData('longitude'));
    
    const [ zoom, setZoom ]               = useState(InitialData('zoom'));
    const [ inclination, setInclination ] = useState(InitialData('inclination'));

    const [ iconSmallWidth, setIconSmallWidth ]   = useState(InitialData('icon_small_width'));
    const [ iconSmallHeight, setIconSmallHeight ] = useState(InitialData('icon_small_height'));
    
    const [ iconAverageWidth, setIconAverageWidth ]   = useState(InitialData('icon_average_width'));
    const [ iconAverageHeight, setIconAverageHeight ] = useState(InitialData('icon_average_height'));
    
    const [ iconBigWidth, setIconBigWidth ]   = useState(InitialData('icon_big_width'));
    const [ iconBigHeight, setIconBigHeight ] = useState(InitialData('icon_big_height'));
    
    const [ listPoint, setListPoint ]     = useState(InitialData('list_point'));

    const [ listCategory, setListCategory ] = useState(ShowCategory());
    const [ idCategory, setIdCategory ]     = useState(0);

    const AnyReactComponent = ({ text, img_src, showImage, className }) => <div className={ "show_point " + className } style={ { display: showImage ? 'block' : 'none' } }><img src={ img_src } className={ "img_point " + className } /><span className="text">{ text }</span></div>;

    const defaultMapOptions = {
        styles: [
            {
                "featureType": "all",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]
    };

    function ShowCategory(){
        const newData = [];
        listPoint.map((elem, index)=>{
            if(newData.find(item => item.id == elem.id_category)){ }else {
                newData.push({ "id": elem.id_category, "name": elem.name_categoory });
            }
        })
        newData.sort((a, b) => {
            const nameA = a.name;
            const nameB = b.name;

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        return newData;
    }

    function OpenCategory(id, value, index){
        const newData = [...listPoint];
        newData.map((elem, index)=>{
            if(id == idCategory){
                elem.statusIcon = true;
            }else{
                if(value){
                    if(elem.id_category == id){ 
                        elem.statusIcon = value;
                    }else {  
                        elem.statusIcon = false;
                    }
                }else {
                    elem.statusIcon = true;  
                }
            }
        })

        if(id == idCategory){
            setIdCategory(0);
        }else {
            setIdCategory(id);
        }
        setListPoint(newData);
    }

    function MapLoaded(dataMap){ 
        dataMap.map.setTilt(60);
        dataMap.map.setCenter({ "lat": latitude, "lng": longitude });
        dataMap.map.setZoom(17);   
        dataMap.map.mapTypeControl = true;
    }

    function InitialData(type){
        return dataPage[type];
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "map_poi", "type": "delete_data", "id" : id, "name": value, "setLoading": props.setLoading });
        SetModalState('Confirmation', true);        
    }

    function HandleData(type, index, value){
        const newData = [...listPoint];
        if(type == 'icon'){
            if(value){
                newData[index][type]     = value;
                newData[index]['status'] = true;
            }else {
                newData[index][type]     = value;
                newData[index]['status'] = false;
            }
        }else {
            newData[index][type] = value;
        }
        setListPoint(newData);
    }

    function PointCancelData(lat, lng){
        const newData = [...listPoint];
        setListPoint(newData);
    }

    function PointHandleData(value){
        const newData = [...listPoint];
        setListPoint(newData);
    }

    function ClickePoint(value) {
        setListPoint([...listPoint, { "id": 0, "type": "", "title": "", "latitude": value.lat, "longitude": value.lng, "icon": "pin_not_file.svg" }]);

        SetModalData('AddPointMap', { "data": value, "HandleData": PointHandleData, "CancelData": PointCancelData });
        SetModalState('AddPointMap', true);
    }

    function AltSizeIcon(id, type){
        SetModalData('SizeIcon', { "idIcon": id, "type": type, "setLoading": props.setLoading });
        SetModalState('SizeIcon', true);
    }

    function CheckedZoom(event){
        const newData = [...listPoint]; 
        if(event.zoom < 16.78){
            newData.map((elem, index)=>{
                if(elem.type == 3){
                    elem.statusIcon = false;
                }
            })
        }
        
        if(event.zoom < 16){
            newData.map((elem, index)=>{
                elem.statusIcon = true;                
            })
        }
        
        if(event.zoom < 15){
            newData.map((elem, index)=>{
                elem.statusIcon = false;                
            })
        }
        setListPoint(newData);
    }

    function OpenFile(file, width, height){
        SetModalData('ShowFile', { "typeFile": "alone", "type": "showIcon", "file": file, "width": width, "height": height });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_MapPoi(userId, title, text, latitude, longitude, listPoint, zoom, inclination, iconSmallWidth, iconSmallHeight, iconAverageWidth, iconAverageHeight, iconBigWidth, iconBigHeight, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('map_poi', setDataPage);
        RegisterDataPage('map_list_type', setCategory);

        return ()=>{
            UnRegisterDataPage('map_poi', setDataPage);
            UnRegisterDataPage('map_list_type', setCategory);
        };
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('map_poi'));
        setCategory(GetDataPage('map_list_type'))

        setTitle(InitialData('title'));
        setText(InitialData('text'));
        setLatitude(InitialData('latitude'));
        setLongitude(InitialData('longitude'));
        
        setZoom(InitialData('zoom'));
        setInclination(InitialData('inclination'));

        setIconSmallWidth(InitialData('icon_small_width'));
        setIconSmallHeight(InitialData('icon_small_height'));
        
        setIconAverageWidth(InitialData('icon_average_width'));
        setIconAverageHeight(InitialData('icon_average_height'));
        
        setIconBigWidth(InitialData('icon_big_width'));
        setIconBigHeight(InitialData('icon_big_height'));

        setListPoint(InitialData('list_point'));
        setListCategory(ShowCategory());
        setIdCategory(0);
    }, [dataPage]);

    return(
        <div className="Page_MapPoi">
            <form onSubmit={ SaveData }>
                <div className="new_block">
                    <div className="div_name_page">
                        <div className="name_fixed">
                            Dashboard
                        </div>
                        <div className="show_icon">
                            <SvgSetaRight color="#6a6a6a" className="icons" />
                        </div>
                        <div className="name_page">
                            Map and Poi
                        </div>
                    </div>
                    <button className="new_block_text">
                        <div className="">
                            <SvgSave color="#ffffff" className="icons" />
                        </div>
                        <div className="">
                            Salvar
                        </div>
                    </button>
                </div>

                <div className="show_page_data">
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" />
                            <span className="name_input">Título</span>
                        </div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setLatitude(e.target.value) } } value={ latitude } maxLength="40" />
                            <span className="name_input">Latitude</span>
                        </div>
                        <div className="div_input space_div">
                            <input type="text" onChange={ (e)=>{ setLongitude(e.target.value) } } value={ longitude } maxLength="40" />
                            <span className="name_input">Longitude</span>
                        </div>
                    </div>
                    <div className="list_input_data">   
                        <div className="div_input space_div">
                            <JoditEditor config={ config } value={ text.replaceAll('&#34;', '"') } onBlur={ newContent => setText(newContent) } />
                        </div>
                    </div>
                </div>

                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Definições do mapa</div>
                    </div>
                    <div className="list_input_data">
                        <div className="div_input">
                            <input type="text" className="zoom" onChange={ (e)=>{ setZoom(e.target.value) } } value={ zoom } maxLength="3" />
                            <span className="name_input">Zoom</span>
                        </div>
                        <div className="div_input">
                            <select onChange={ (e)=>{ setInclination(e.target.value) } } value={ inclination } className="" required>
                                <option value="0">Sem inclinação</option>
                                <option value="20">20</option>
                                <option value="40">40</option>
                                <option value="60">60</option>
                            </select>
                            <span className="name_input">Inclinação</span>
                        </div>
                    </div>
                    <div className="list_input_data space_top">
                        <div className="list_input_data show_data_text size_icon">
                            <div className="position_icon">Ícone pequeno</div>
                            <div className="div_input">
                                <input type="text" className="zoom" onChange={ (e)=>{ setIconSmallWidth(e.target.value) } } value={ iconSmallWidth } maxLength="3" readOnly />
                                <span className="name_input">Largura</span>
                            </div>
                            <div className="div_input">
                                <input type="text" className="zoom" onChange={ (e)=>{ setIconSmallHeight(e.target.value) } } value={ iconSmallHeight } maxLength="3" />
                                <span className="name_input">Altura</span>
                            </div>
                            <span className="div_input div_add_img">
                                <div className="show_img_ type_icon" onClick={ ()=>{ OpenFile('./assets/opt1.png', iconSmallWidth, iconSmallHeight) } }>
                                    <img alt="img" src="./assets/opt1.png" className="icons" />
                                </div>
                            </span>
                        </div>
                        <div className="list_input_data show_data_text size_icon">
                            <div className="position_icon">Ícone médio</div>
                            <div className="div_input">
                                <input type="text" className="zoom" onChange={ (e)=>{ setIconAverageWidth(e.target.value) } } value={ iconAverageWidth } maxLength="3" readOnly />
                                <span className="name_input">Largura</span>
                            </div>
                            <div className="div_input">
                                <input type="text" className="zoom" onChange={ (e)=>{ setIconAverageHeight(e.target.value) } } value={ iconAverageHeight } maxLength="3" />
                                <span className="name_input">Altura</span>
                            </div>
                            <span className="div_input div_add_img">
                                <div className="show_img_ type_icon" onClick={ ()=>{ OpenFile('./assets/opt1.png', iconAverageWidth, iconAverageHeight) } }>
                                    <img alt="img" src="./assets/opt1.png" className="icons" />
                                </div>
                            </span>
                        </div>
                        <div className="list_input_data show_data_text size_icon">
                            <div className="position_icon">Ícone grande</div>
                            <div className="div_input">
                                <input type="text" className="zoom" onChange={ (e)=>{ setIconBigWidth(e.target.value) } } value={ iconBigWidth } maxLength="3" readOnly />
                                <span className="name_input">Largura</span>
                            </div>
                            <div className="div_input">
                                <input type="text" className="zoom" onChange={ (e)=>{ setIconBigHeight(e.target.value) } } value={ iconBigHeight } maxLength="3" />
                                <span className="name_input">Altura</span>
                            </div>
                            <span className="div_input div_add_img">
                                <div className="show_img_ type_icon" onClick={ ()=>{ OpenFile('./assets/opt1.png', iconBigWidth, iconBigHeight) } }>
                                    <img alt="img" src="./assets/opt1.png" className="icons" />
                                </div>
                            </span>
                        </div>
                    </div>
                </div>

                {
                    listPoint.length == 0 ? null :
                    <div className="show_page_data">
                        {
                            listPoint.map((elem, index)=>{
                                if(elem.id !=0){
                                    return(
                                        <div className="show_data_text" key={ index }>
                                            <div className="position_icon">Posição { index + 1 }</div>
                                            <div className="list_input_data">
                                                <div className="div_input space_div">
                                                    <select onChange={ (e)=>{ HandleData('id_category', index, e.target.value) } } value={ elem.id_category } className="" required>
                                                        <option value="">Selecione uma categoria</option>
                                                        <option value="newCategory">Nova categoria</option>
                                                        {
                                                            category.map((elem, index)=>{
                                                                return(
                                                                    <option value={ elem.id } key={ index }>{ elem.name }</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    <span className="name_input">Categoria</span>
                                                </div>
                                                {
                                                    elem.id_category == "newCategory" ?
                                                    <div className="div_input space_div">
                                                        <input type="text" className="" onChange={ (e)=>{ HandleData('new_category', index, e.target.value) } } value={ elem.new_category } required />
                                                        <span className="name_input">Nome da nova categoria</span>
                                                    </div> : null
                                                }
                                                <div className="div_input">
                                                    <select onChange={ (e)=>{ HandleData('show_icon', index, e.target.value) } } value={ elem.show_icon } className="show_icon" required>
                                                        <option value="true">Sim</option>
                                                        <option value="false">Não</option>
                                                    </select>
                                                    <span className="name_input">Ícone fixo</span>
                                                </div>
                                                <div className="div_input" onClick={ ()=>{ DeleteData(elem.id, elem.title); } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                            <div className="list_input_data">
                                                <div className="div_input space_div">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleData('title', index, e.target.value) } } value={ elem.title } maxLength="140" />
                                                    <span className="name_input">Título</span>
                                                </div>
                                                <div className="div_input">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleData('latitude', index, e.target.value) } } value={ elem.latitude } style={ { textAlign: 'center' } } />
                                                    <span className="name_input">Latitude</span>
                                                </div>
                                                <div className="div_input">
                                                    <input type="text" className="" onChange={ (e)=>{ HandleData('longitude', index, e.target.value) } } value={ elem.longitude } style={ { textAlign: 'center' } } />
                                                    <span className="name_input">Longitude</span>
                                                </div>
                                                <div className="div_input new_file_add" onClick={ ()=>{ AltSizeIcon(elem.id, elem.type) }}>
                                                    Ícone:
                                                    {
                                                        elem.type == 1 ? " Pequeno" : elem.type == 2 ? " Médio" : " Grande"
                                                    }
                                                </div>
                                                <span className="div_input div_add_img icon_map">
                                                    <div className={ elem.status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            { elem.status == true ? "Ícone adicionado" : "Adicionar ícone" }
                                                            <input type="file" onChange={ (e)=>{ HandleData('icon', index, e.target.files[0]); } } accept="image/*" />
                                                        </label>
                                                    </div>
                                                    {
                                                        elem.icon == '' ? null :
                                                            elem.status == true ?
                                                        <img alt="icon svg" src={ URL.createObjectURL(elem.icon) } className="icons" /> : 
                                                        <img alt="icon svg" src={ elem.icon } className="icons" />
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                }

                <div className="show_page_data show_map">
                    {
                        latitude == "" || longitude == "" ? 
                        <div className="text_notification">
                            É necessário adicionar a latitude e longitude para exibir o mapa
                        </div>
                        :
                        <>
                            <div className="list_btn_category">
                                <div className="title">Categorias</div>
                                {
                                    listCategory.map((elem, index)=>{
                                        return(
                                            <div className={ idCategory == elem.id ? "name_category category_active" : "name_category" } key={ index } onClick={ ()=>{ OpenCategory(elem.id, !elem.statusIcon, index) }}>
                                                { elem.name }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="div_show_map">
                                <GoogleMapReact 
                                    bootstrapURLKeys={ { key: "AIzaSyC4EnMG7H-fmsU4vXtSvLVhnzPZTEqS4nw" } }
                                    defaultCenter={ { "lat": latitude, "lng": longitude } }
                                    defaultZoom={ 17 }
                                    onClick={ (e)=>{ ClickePoint(e) } }
                                    onChange={ (e)=>{ CheckedZoom(e) }}
                                    options={ {
                                        mapId: "df32285f2c0c7c44"
                                    } }             
                                    
                                    defaultOptions={ defaultMapOptions }

                                    onGoogleApiLoaded={ (dataMap)=>{ MapLoaded(dataMap) } }
                                    yesIWantToUseGoogleMapApiInternals
                                >
                                    {
                                        listPoint.map((elem, index)=>{
                                            let typeClassSize = "";
                                            switch (elem.type) {
                                                case 1: case '1': typeClassSize = "opt_1 id_ctg_" + elem.id_category; break;
                                                case 2: case '2': typeClassSize = "opt_2 id_ctg_" + elem.id_category; break;
                                                case 3: case '3': typeClassSize = "opt_3 id_ctg_" + elem.id_category; break;
                                            } 

                                            return(
                                                <AnyReactComponent
                                                    key= { index }
                                                    lat= { elem.latitude }
                                                    lng= { elem.longitude }
                                                    text= { elem.title }
                                                    className={ typeClassSize }
                                                    showImage={ elem.statusIcon }
                                                    img_src= { elem.status == true ? URL.createObjectURL(elem.icon) : elem.icon }
                                                />
                                            )
                                        })
                                    }
                                </GoogleMapReact>
                            </div>
                        </>
                    }
                </div>
            </form>
            <PopUP_AddPointMap />
            <PopUP_SizeIcon />
        </div>
    )
}